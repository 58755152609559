import HeroSectionSlider from '@/containers/landingPage/HeroSectionSlider';
import React from 'react';
import Calendar from './Calendar';
import FeatureInfo from './featureSection/FeatureInfo';
import FeatureMap from './featureSection/FeatureMap';
import HomeDemoTestimonial from './HomeDemoTestimonial';
import MembersTabs from './MembersTabs';
import PartnerEvents from './PartnerEvents';

const slides = [
  {
    id: 1,
    title: 'IFSCA-IVCA Fund Management',
    desc: '12th March 2025 | GIFT City, Gujarat ',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://forms.gle/49sHNz6jrcKKnCVK7',
    },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/WhatsAppImage2025-03-10at45810PM-SvICsFvwxg82.jpg',
    },
  },
  {
    id: 1,
    title: 'IVCA Climate & Sustainability Council Initiatives',
    desc: 'Mobilizing Capital for a Sustainable India. ',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://www.ivca.in/climate-and-sustainability-council',
    },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/Rectangle4339-XZbabFT67YnR.svg',
      md: 'https://static.levocdn.com/WGLBUT82/Bannerformobile2-UpLgWyVqhyPC.svg',
    },
  },
  {
    id: 1,
    title: 'Startup Mahakumbh',
    desc: '3rd - 5th April 2025 | Bharat Mandapam, New Delhi ',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://startupmahakumbh.org',
    },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/SMK20WebBanner800x400pixels-T71Rn3TYdfuD.jpg',
    },
  },

  {
    id: 2,
    title: 'IVCA Conclave 2025',
    desc: '11th - 12th Feb 2025 | Trident Hotel, Nariman Point Mumbai ',
    showBtn: true,
    buttons: {
      text: 'Explore Conclave Highlights',
      url: 'https://ivcaconclave.com',
    },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/Screenshot2024-12-23at54847PM-tryvJ04S1VcA.png',
      //   lg: 'https://static.levocdn.com/WGLBUT82/IVCAConclavebanner13-12WebsiteBanner-IVCAConclave1-4JOFlpJmdB4X.jpg',
    },
    // contactInfo: (
    //   <Box
    //     w={{ xs: '100%', md: '60%' }}
    //     mt={{ xs: '10px', md: '0px' }}
    //     mb={{ xs: '-30px', md: '20px' }}
    //   >
    //     <Text
    //       color="white"
    //       fontSize={{ xs: '16px', md: '20px' }}
    //       fontWeight="bold"
    //     >
    //       For more information and partnership opportunities, write to us at
    //       <Link
    //         fontWeight={600}
    //         href="mailto:Aakriti@ivca.in"
    //         color="primary.500"
    //         px="6px"
    //       >
    //         Aakriti@ivca.in
    //       </Link>
    //       and
    //       {/* <Box as="br" display={{ xs: 'block', md: 'none' }} /> */}
    //       <Link
    //         fontWeight={600}
    //         href="mailto:Shreya@ivca.in"
    //         color="primary.500"
    //         px="6px"
    //       >
    //         Shreya@ivca.in
    //       </Link>
    //     </Text>
    //     <Flex mt={{ xs: '12px', md: '18px' }} gap={{ xs: '4px', md: '12px' }}>
    //       <Link
    //         href="https://ivcaconclave.com/"
    //         cursor="pointer"
    //         aria-label="btn"
    //         target="_blank"
    //       >
    //         <Button
    //           borderRadius="none"
    //           fontSize={{ xs: '12px', md: '18px' }}
    //           fontWeight={700}
    //           bgColor="white"
    //         >
    //           Learn More
    //         </Button>
    //       </Link>
    //       <Link
    //         href="https://static.levocdn.com/WGLBUT82/IVCA-RipeMagazine2024WithADScompressed1-Bfj3oGUKhmnh.pdf"
    //         cursor="pointer"
    //         aria-label="btn"
    //         target="_blank"
    //       >
    //         <Button
    //           fontSize={{ xs: '12px', md: '18px' }}
    //           borderRadius="none"
    //           fontWeight={700}
    //           bgColor="white"
    //         >
    //           RIPE Magazine 2024
    //         </Button>
    //       </Link>
    //     </Flex>
    //   </Box>
    // ),
  },

  {
    id: 2,
    title: 'Welcome to IVCA',
    desc: 'Indian Venture and Alternate Capital Association (IVCA) is a not-for-profit, apex industry body promoting the alternate capital industry and fostering a vibrant investing ecosystem in India.',
    showBtn: true,
    buttons: { text: 'About Us', url: '/about-us' },
    banner: { lg: '/webp/homeSlideOne.webp' },
  },

  {
    id: 4,
    title: 'SEBI AI Simplification',
    desc: 'SEBI AI Simplification of Requirements',
    showBtn: true,
    buttons: { text: 'Read More', url: '/sebiupdates/accredited-investor' },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/7130124319659207262.png',
    },
  },
  {
    id: 5,
    title: 'Regulatory Reporting - SEBI',
    desc: 'Regulatory Reporting by AIFs - Circular Issued by SEBI',
    showBtn: true,
    buttons: {
      text: 'Read More',
      url: '/sebiupdates/sebi-quarterly-reporting-by-aifs',
    },
    banner: { lg: '/webp/citySkyline.webp' },
  },
];

const HomePage: React.FunctionComponent = () => (
  <>
    <HeroSectionSlider slides={slides} />
    <FeatureInfo />
    <FeatureMap />
    <Calendar />
    <PartnerEvents />
    <MembersTabs />
    <HomeDemoTestimonial />
  </>
);

export default HomePage;
