import {
  Text,
  Img,
  Heading,
  Box,
  SimpleGrid,
  Flex,
  Button,
  Link,
} from '@chakra-ui/react';

import React from 'react';

const information = [
  // {
  // 	title: "ASIA Private Equity Forum 2025",
  // 	link: "http://apef.hkvca.com.hk",
  // 	cover:
  // 		"https://static.levocdn.com/WGLBUT82/BannerSuppIVCA-01-hjB8vGX7kWYW.png",
  // 	btnText: "Register Now",
  // 	desc: (
  // 		<>
  // 			<Text fontWeight={600} variant="featureText" textAlign="justify">
  // 				APEF is a leading annual industry event in the region, organized by
  // 				the Hong Kong Venture Capital and Private Equity Association (HKVCA),
  // 				Asia's oldest and largest private equity industry group. The event
  // 				will take place on January 15, 2025, during the Hong Kong government's
  // 				high-profile international financial week and immediately following
  // 				the Asian Financial Forum. The focus of the discussions will be on
  // 				Asian private equity, particularly in China, Japan, India and
  // 				Southeast Asia, covering topics such as family offices, private
  // 				credit, venture capital, sector-focused funds, and regulation.
  // 			</Text>
  // 			<Text
  // 				my="8px"
  // 				fontWeight={800}
  // 				variant="featureText"
  // 				textAlign="justify"
  // 			>
  // 				Location: Hong Kong
  // 			</Text>
  // 			<Text variant="featureText" fontWeight={800} textAlign="justify">
  // 				IVCA members can enjoy 20% discount for registration.
  // 			</Text>
  // 		</>
  // 	),
  // },
  {
    title: 'Global Private Capital Conference',
    link: 'https://cvent.me/e5Mv3G',
    cover:
      'https://static.levocdn.com/WGLBUT82/GPCConference2025SocialPromo-dO45wphYsZSd.png',
    btnText: 'Register Now',
    desc: (
      <>
        <Text
          fontWeight={500}
          variant="featureText"
          textAlign="justify"
          my="16px"
        >
          Join us at the GPC Conference 2025 in New York City from 7-9 April
          2025, at Convene 360 Madison. <br /> The Global Private Capital
          Conference is a must-attend event for investors, LPs and entrepreneurs
          looking to gain insights into global private capital and trends and
          opportunities across Asia, Latin America, Africa, CEE and the Middle
          East. <br />
          Participants include a curated group of family offices, endowments,
          pensions, sovereign wealth funds, development finance institutions,
          experienced fund managers and new investment teams active across
          private equity, venture capital, impact, infrastructure and credit. We
          look forward to insightful discussions and meaningful connections.
        </Text>
        <Box>
          <Text variant="featureText" textAlign="justify" fontWeight={600}>
            Date: 7th & 9th April 2025
          </Text>
          <Text
            mt="4px"
            fontWeight={600}
            variant="featureText"
            textAlign="justify"
          >
            Location: New York City
          </Text>
          <Text
            mt="14px"
            variant="featureText"
            fontWeight={800}
            textAlign="justify"
          >
            IVCA Members get 15% Discount
          </Text>
        </Box>
      </>
    ),
  },
  //   {
  //     title: 'The world of private capital meets in Asia',
  //     link: 'https://informaconnect.com/superreturnasia/',
  //     cover: '/jpeg/SuperReturn.jpeg',
  //     btnText: 'Register Now',
  //     desc: (
  //       <Text variant="featureText" textAlign="justify">
  //         <Text as="span" textAlign="left">
  //           Location: Marina Bay Sands Convention Centre Singapore.
  //         </Text>
  //         <br />
  //         Partner Promo Code: FKR3538IVCA
  //       </Text>
  //     ),
  //   },
];

const PartnerEvents: React.FC = () => (
  <Flex
    justifyContent={{ xs: 'center', lg: 'flex-start' }}
    pl={{ xs: '0%', lg: '10%', xl: '8%' }}
    bgColor="bg.lightWhite"
    py="5px"
  >
    <Box id="media-coverage" pt={{ xs: '60px' }} maxW="container.2xl" w="90%">
      <Heading
        p="0"
        fontSize={{ xs: '22px', sm: '28px', md: '30px', lg: '42px' }}
        lineHeight={{ xs: '30px', md: '40px', lg: '54.6px' }}
        fontWeight="800"
      >
        Partner Events
      </Heading>
      <SimpleGrid
        w="100%"
        mt="24px"
        gap="2rem"
        columns={{ xs: 1, md: 2, xl: 3 }}
      >
        {information?.map((info) => (
          <Box
            boxShadow="md"
            borderRadius="xl"
            overflow="hidden"
            key={info?.title}
          >
            <Img height="250px" w="100%" src={info.cover} alt={info.title} />
            <Box p="1rem">
              <Heading as="h3" variant="featureTitle">
                {info.title}
              </Heading>
              <Box
                overflowY="scroll"
                css={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {typeof info?.desc === 'string' ? (
                  <Text variant="featureText" textAlign="justify">
                    {info?.desc}
                  </Text>
                ) : (
                  info?.desc
                )}
              </Box>
              <Link href={info.link} aria-label="learn-more" target="_blank">
                <Button
                  colorScheme="primary"
                  aria-label="learn-more"
                  w={{ xs: '175px' }}
                  h={{ xs: '30px', md: '40px' }}
                  mt="15px"
                >
                  {info.btnText}
                </Button>
              </Link>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  </Flex>
);

export default PartnerEvents;
