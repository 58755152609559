import React, { useEffect, useState } from 'react';
import { Box, Img, Heading } from '@chakra-ui/react';
import format from 'date-fns/format';
import EventHighlightsModal from '@/components/modals/EventHighlightsModal';
import EventsCalendarCard from '@/components/eventsCalendar/EventsCalendarCard';
import { getContentList } from '@/lib/apiService';

export type DateFixedType = '' | 'Date' | 'Month' | 'Year';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
interface IEvent {
  venue: string;
  _id: string;
  event_name: string;
  date_fixed: DateFixedType;
  date: string;
  date_to: string;
  event_type: string;
  description: string;
  event_highlights: any;
  speakerRole: any;
  picturesLink: any;
  websiteLink: any;
  speaker: any;
}
const Calendar: React.FunctionComponent = () => {
  const [homeSelectedDate, setHomeSelectedDate] = useState<string>('');
  const [defaultRender, setDefaultRender] = useState<boolean>(true);
  const [monthMatch, setMonthMatch] = useState<boolean>(false);
  const [upcomingHomeEventsData, setUpcomingHomeEventsData] = useState<
    IEvent[]
  >([]);
  const [finalData, setFinalData] = useState<IEvent[]>([]);
  const [selectedMonth, setSelectedMonth] = useState('');

  useEffect(() => {
    getContentList<IEvent>('events', {
      limit: -1,
    })
      .then((res) => {
        setUpcomingHomeEventsData(res?.content?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const customSort = (a: any, b: any) => {
    const dateA = new Date(a?.date);
    const dateB = new Date(b?.date);
    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  };

  useEffect(() => {
    if (defaultRender) {
      const upcoming = () => {
        const remainingFilter = upcomingHomeEventsData.filter((item) => {
          //   if (
          //     format(new Date(item.date), 'yyyy-L-d') ===
          //     format(new Date(), 'yyyy-L-d')
          //   )
          //     return 1;
          if (
            new Date(item?.date).getMonth() === new Date().getMonth() &&
            new Date(item?.date).getFullYear() === new Date().getFullYear()
          )
            return 1;
          if (new Date(item?.date).getTime() > new Date().getTime()) return 1;
          return 0;
        });
        return remainingFilter.sort(customSort);
      };
      if (selectedMonth === '') {
        setFinalData(upcoming());
      } else {
        const value = format(new Date(selectedMonth), 'MMMM-yyyy');

        const filterData = upcoming().filter(
          (item) =>
            `${months[new Date(item?.date).getMonth()]}-${new Date(
              item?.date
            ).getFullYear()}` === value
        );

        setFinalData(filterData);
      }
    } else {
      const filterDate = () => {
        const newFilter = upcomingHomeEventsData.filter((item) => {
          //   if (
          //     `${new Date(item.date).getDate()} ${
          //       months[new Date(item.date).getMonth()]
          //     } ${new Date(item.date).getFullYear()}` === homeSelectedDate
          //   )
          //     return 1;
          // homeSelectedDate = "22 March 2023"
          if (
            months[new Date(item?.date).getMonth()] ===
              homeSelectedDate.split(' ')[1] &&
            new Date(item?.date).getFullYear() ===
              Number(homeSelectedDate.split(' ')[2])
          )
            return 1;
          return 0;
        });
        return newFilter;
      };
      setFinalData(filterDate());
    }
  }, [defaultRender, homeSelectedDate, upcomingHomeEventsData, selectedMonth]);

  useEffect(() => {
    const monthMatcher = () => {
      const filteredTo = finalData.filter((item: any) => item?.date_to !== '');
      return filteredTo;
    };

    const monthMatcherFn = () => {
      const filteredDates = monthMatcher().filter((item: any) => {
        if (
          new Date(item?.date_to).getMonth() === new Date(item?.date).getMonth()
        ) {
          setMonthMatch(true);
          return 1;
        }
        setMonthMatch(false);
        return 0;
      });
      return filteredDates;
    };

    monthMatcherFn();
  }, [finalData, monthMatch]);

  const [modalOpen, setModalOpen] = useState({ isOpened: false, data: [] });

  const onClosed = () => {
    setModalOpen({ isOpened: false, data: [] });
  };

  return (
    <>
      <Box as="section" w="100%" pos="relative" bgColor="bg.lightWhite">
        <Box w="100%" pos="absolute" left="0" right="0" top="0" bottom="0">
          <Img
            src="/webp/calendar_bg.webp"
            alt="background meeting image"
            w="100%"
            h="100%"
            zIndex="-10"
            objectFit="cover"
          />
        </Box>
        <Box
          position="relative"
          pt="82px"
          pb="40px"
          zIndex="1"
          w={{ xs: '90%', xl: '1240px' }}
          mx="auto"
        >
          <Box
            maxW={{ xs: '100%', xl: '1240px' }}
            h="100%"
            textAlign="center"
            pt="27px"
            pb="36px"
            px={{ xs: '20px', md: '140px', lg: '200px' }}
            bgColor="rgba(0, 0, 0, 0.3)"
            backdropFilter="blur(4px)"
          >
            <Heading as="h2" variant="calendarTitle" mb="14px">
              Upcoming IVCA Initiatives
            </Heading>
          </Box>
          <Box
            pt="40px"
            pl={{ xs: '20px', md: '40px', xl: '53px' }}
            pr={{ xs: '20px', md: '40px', xl: '59.21px' }}
            pb={{ xs: '20px', sm: '40px', lg: '60px' }}
            bgColor="white"
          >
            <EventsCalendarCard
              finalData={finalData}
              setModalOpen={setModalOpen}
              setHomeSelectedDate={setHomeSelectedDate}
              setDefaultRender={setDefaultRender}
              setSelectedMonth={setSelectedMonth}
              isHomePage
            />
          </Box>
        </Box>
      </Box>
      <EventHighlightsModal
        isOpen={modalOpen.isOpened}
        images={modalOpen.data}
        onClose={onClosed}
      />
    </>
  );
};

export default Calendar;
